/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/starkey/muse/'], ['en', 'https://www.hear.com/hearing-aids/brands/starkey/muse/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/starkey/muse/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/starkey/muse/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "introducing-the-starkey-muse",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introducing-the-starkey-muse",
    "aria-label": "introducing the starkey muse permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introducing the Starkey Muse"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For those who don’t know, Starkey Hearing Technologies is one of the biggest names in the world of hearing aids and other auditory technology. Based in the USA, Starkey has been trendsetters for decades in fields like miniaturization, wireless technology, comfortable in-canal hearing aids and programmable settings – and the Starkey Muse builds on their reputation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Muse is actually a family of hearing aids which Starkey says is their “most popular” ever. It’s certainly been a big hit among Americans with hearing loss, mainly thanks to its combination of comfort, cosmetic quality and technological sophistication. Those three properties are generally important to hearing aid wearers, and the Starkey Muse delivers on all counts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, let’s find out more about the various options available in the Muse family, and try to help you decide which hearing aid is right for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-range-of-hearing-aids-with-convenience-at-heart",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-range-of-hearing-aids-with-convenience-at-heart",
    "aria-label": "a range of hearing aids with convenience at heart permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Range of Hearing Aids with Convenience at Heart"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "First up, it might help to run through the different options customers can choose from when they pick a Starkey Muse device. The family includes both RIC (receiver-in-canal) and micro-RIC variations, as well as mini BTE (behind-the-ear) and “power” BTE options which are larger but hold their charge a little bit longer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You might also think about their CIC (completely-in-canal) options, along with their ITC (in-the-canal) and ITE (in-the-ear) Muse devices. It’s probably obvious by now that the Muse collection spans a huge variety of different designs. So, if you’ve always been a fan of CIC or BTE hearing aids, there should be a model that suits your needs. With that in mind, let’s take a look at the technology that powers these tiny super computers."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "high-quality-audio-and-programmable-settings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#high-quality-audio-and-programmable-settings",
    "aria-label": "high quality audio and programmable settings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "High quality audio and programmable settings"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Muse family has been optimized to deliver natural sounding audio in almost any setting. If you prioritize conversations or speech in general, the Muse family’s Acuity Directional systems will enhance your experience, picking up words and voices from any angle and filtering out extraneous noise. Some hearing aids can be overwhelmed a little by complex noise environments, but this is less of a problem with the Muse collection."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They even deliver around 6 decibels of audio enhancement, making speech even crisper and clearer. At the same time, the Muse family features something called Acuity Binaural Imaging – another bonus for conversational hearing. Through advanced components and processing software, Muse hearing aids deliver a binaural listening experience which gets much closer to actual, natural hearing than previous generations of devices. Another feature which helps with complex social lives is the Muse’s Lifescape Analyzer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This innovative system detects ambient conditions and calibrates the hearing aid to handle different noise levels. Its seven sound classes should cover everything from musical performances or theater visits to work presentations and family meals."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "specialist-features-for-modern-lifestyles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#specialist-features-for-modern-lifestyles",
    "aria-label": "specialist features for modern lifestyles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Specialist Features for Modern Lifestyles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As with most advanced hearing aid manufacturers, Starkey has also started to focus on specific aspects of their customers’ lives. In the case of the Muse collection of hearing aids, this includes some exciting features. For example, the Synergy platform delivers streaming music in high definition, even making soft and subtle sounds available for wearers to enjoy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All Muse hearing aids also come with ear-to-ear streaming for smartphone users, ensuring they can hear conversations or any multimedia content they want to stream from their phones. Then there are the other streaming accessories that Starkey have developed. Muse users can add things like the SurfLink Remote Microphone, which streams specific sound sources directly to your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can also add the SurfLink Remote, or tiny Bluetooth streaming pods that connect to any Bluetooth-enabled devices. It all adds up to a suite of accessories and systems which fit seamlessly into our modern lives."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-that-offer-hearing-loss-relief",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-that-offer-hearing-loss-relief",
    "aria-label": "hearing aids that offer hearing loss relief permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids That Offer Hearing Loss Relief"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another aspect of the Starkey Muse family that is worth mentioning is how they cater to all kinds of hearing loss and auditory conditions. For instance, if you suffer from loss of hearing in one ear, the Muse CROS system can handle your needs, balancing out streams and sounds to create a natural, comfortable hearing experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And there’s some relief available for tinnitus sufferers as well, thanks to Starkey’s Multiflex technology. If you struggle with ringing in your ears, this system could be the answer, helping to ease the discomfort of tinnitus while enhancing noise to make conversations more audible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "durable-convenient-hearing-aids-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#durable-convenient-hearing-aids-for-you",
    "aria-label": "durable convenient hearing aids for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Durable, Convenient Hearing Aids for You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, Muse owners will find that their devices are long-lasting and easy to use. Every Muse unit is protected by what Starkey calls a Surface Nanoshield. Formed from advanced polymers, this surface actively repels ear wax, helping to maintain its performance and extend its life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The range also features a massive amount of choice. Just to take a couple of examples, the Muse Power Plus BTE 13 comes in i1000, i1200, i1600 i2000, i2400 versions, with varying power levels for different degrees of hearing loss, and the same applies to their CIC varieties. Fundamentally, Starkey has developed a family of hearing aids for almost all users, whatever their hearing loss needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you like the features and range of options from Starkey’s Muse family, why not give them a try? By filling out our questionnaire, you can arrange a risk-free trial to try a Starkey Muse hearing aid. Our network of Partner Providers will surely include an expert near you who will be happy to fit your desired model. It could be just what you need to improve your hearing and boost your quality of life."), "\n", React.createElement(ButtonCta, {
    copy: "Try the Starkey Muse now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
